<template>
  <div class="dashboard">
    <div class="dash-content" v-if="activeTenant">
      <div class="tenant-view-section">
        <div class="left">
          <div class="tenant-card">
            <div class="img">
              <img :src="$upload(activeTenant.tenant_image)" alt="tenant" />
            </div>
            <div class="name" v-if="activeTenant.tenant_type == 'company'">
              {{ activeTenant.company_name }}
            </div>
            <div class="name" v-else>{{ activeTenant.tenant_name }}</div>
            <div class="occupation">{{ activeTenant.tenant_type }}</div>
            <div class="card-stats" v-if="tenantBills">
              <div class="card-stat-item">
                <p>{{ activeTenant.subscriptions.length }}</p>
                <h3>Rooms</h3>
              </div>
              <div class="card-stat-item">
                <p>
                  {{ $amount(tenantBills.total) }}
                </p>
                <h3>Total Bills</h3>
              </div>
              <div class="card-stat-item">
                <p>
                  {{ $amount(tenantBills.unpaid) }}
                </p>
                <h3>Total Unpaid</h3>
              </div>
            </div>
            <div class="card-footer">
              <a class="call" :href="'tel:' + activeTenant.tenant_phone">
                <i class="fas fa-phone-alt"></i> Call
              </a>
              <a class="email" :href="'mailto:' + activeTenant.tenant_email">
                <i class="fas fa-envelope"></i> Email
              </a>
            </div>
          </div>
          <div class="bill-preview" v-if="!showList">
            <div class="bill-preview-header">
              <h1>
                {{ $getHouse().house_name }}
              </h1>
              <div class="contact-info">
                <p>
                  {{ $getHouse().house_address }}
                </p>
                <p>
                  {{ $getHouse().house_phone }}
                </p>
                <p>
                  {{ $getHouse().house_email }}
                </p>

                <p>
                  {{
                    $date(new Date()) +
                    " " +
                    new Date().getHours() +
                    ":" +
                    new Date().getMinutes() +
                    ":" +
                    new Date().getSeconds()
                  }}
                </p>
                <p>TIN: {{ $getHouse().house_tin_number }}</p>
              </div>
              <div class="contact-info">
                <p><b>Billed To:</b></p>
                <p>{{ activeTenant.tenant_name }}</p>
              </div>
              <div class="bill-items">
                <table>
                  <tr>
                    <th>Item</th>
                    <th>UP</th>
                    <th>Count</th>
                    <th>Amount</th>
                  </tr>
                  <tr v-for="item in newSubscription.payments" :key="item.id">
                    <td>{{ item.description }}</td>
                    <td>{{ item.amount }} {{ $getHouse().currency }}</td>
                    <td>{{ item.paid_number }}</td>
                    <td>{{ item.amount * item.paid_number }}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td><b>Total</b></td>
                    <td>
                      <b
                        >{{ $comma(getTotal(newSubscription.total)) }}
                        {{ $getHouse().currency }}</b
                      >
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="tenant-details">
          <div class="details-tabs">
            <button
              class="tab"
              @click="
                activeTab = 'profile';
                $router.push({
                  query: { ...this.$route.query, tab: 'profile' },
                });
              "
              :class="{ active: activeTab === 'profile' }"
            >
              Profile
            </button>
            <button
              class="tab"
              @click="
                activeTab = 'subscriptions';
                $router.push({
                  query: { ...this.$route.query, tab: 'subscriptions' },
                });
              "
              :class="{ active: activeTab === 'subscriptions' }"
            >
              Subscriptions
            </button>
            <button
              class="tab"
              @click="
                activeTab = 'bills';
                $router.push({ query: { ...this.$route.query, tab: 'bills' } });
              "
              :class="{ active: activeTab === 'bills' }"
            >
              Bills
            </button>
          </div>
          <div class="tenant-detail-item" v-if="activeTab == 'profile'">
            <h3
              class="detail-header"
              v-if="activeTenant.tenant_type == 'company'"
            >
              About {{ activeTenant.company_name }}
            </h3>
            <h3 class="detail-header" v-else>
              About {{ activeTenant.tenant_name }}
            </h3>
            <div class="detail-content">
              <p v-if="activeTenant.tenant_type == 'company'">
                <b>Tenant:</b> {{ activeTenant.tenant_name }}
              </p>
              <p><b>Email:</b> {{ activeTenant.tenant_email }}</p>
              <p><b>Phone:</b> {{ activeTenant.tenant_phone }}</p>
              <p><b>Type:</b> {{ activeTenant.tenant_type }}</p>
              <p><b>Company:</b> {{ activeTenant.company_name }}</p>
              <p><b>Company Email:</b> {{ activeTenant.company_email }}</p>
              <p><b>Company Phone:</b> {{ activeTenant.company_phone }}</p>
              <p>
                <b>Status:</b>
                <span :class="'chip ' + activeTenant.status">{{
                  activeTenant.status
                }}</span>
              </p>
            </div>
          </div>
          <div class="tenant-detail-item" v-if="activeTab == 'subscriptions'">
            <div class="subscription-list" :class="showList ? 'active' : ''">
              <div class="flex-btn">
                <h3 class="detail-header">Subscriptions</h3>
                <button class="my-btn" @click="showList = false">
                  <i class="fa fa-plus"></i> Add Subscription
                </button>
              </div>
              <div
                v-if="
                  activeTenant.subscriptions &&
                  activeTenant.subscriptions.length > 0
                "
              >
                <div class="detail-content">
                  <div
                    class="subscription"
                    v-for="sub in activeTenant.subscriptions"
                    :key="sub.id"
                  >
                    <p class="room">{{ sub.room.room_number }}</p>
                    <p><b>Wing:</b> {{ sub.room.wing.wing_name }}</p>
                    <p>
                      <b>Subscription Recurring Amount:</b>
                      {{ $comma(sub.amount) }}
                      {{ activeTenant.house.currency }}
                    </p>
                    <p><b>Start Date:</b> {{ $date(sub.started_at) }}</p>
                    <p><b>Expire Date:</b> {{ $date(sub.expire_at) }}</p>
                    <hr style="border-top: 1px solid #ccc" />
                    <div class="payments">
                      <p>
                        <span><strong>Recurring Payments</strong></span>
                      </p>
                      <div
                        class="payment"
                        v-for="payment in sub.payments"
                        :key="payment.id"
                      >
                        <p v-if="payment.recurring">
                          <b>{{ $comma(payment.description) }}:</b>
                          {{ $comma(payment.amount) }}
                          {{ activeTenant.house.currency }}
                        </p>
                      </div>
                    </div>
                    <p>
                      <b>Status:</b>
                      <span :class="'chip ' + sub.status">{{
                        sub.status
                      }}</span>
                    </p>
                    <div class="sub-btns" v-if="sub.status == 'active'">
                      <div class="btn-group">
                        <button
                          class="success"
                          @click="startRenewSubscription(sub)"
                        >
                          Renew
                        </button>
                        <button class="end" @click="startEndSubscription(sub)">
                          End
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <i class="no-data">No subscriptions found</i>
              </div>
            </div>
            <div
              class="add-subscription-form-section"
              :class="showList ? '' : 'active'"
            >
              <h3 class="detail-header">
                {{ subscriptionActivity }} Subscriptions
              </h3>
              <div class="admin-forms">
                <p><i>Room Details:</i></p>
                <div class="input-group group-2">
                  <div class="form-group">
                    <label for="room">Wing</label>
                    <select
                      id="room"
                      v-model="newSubscription.wing_id"
                      @change="
                        $getFloorsByWing(newSubscription.wing_id);
                        newSubscription.floor_id = null;
                        newSubscription.room_id = null;
                        getRooms();
                      "
                    >
                      <option :value="null">Select Wing</option>
                      <option
                        v-for="wing in wings"
                        :value="wing.wing_id"
                        :key="wing.wing_id"
                      >
                        {{ wing.wing_name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="room">Floor</label>
                    <select
                      id="room"
                      v-model="newSubscription.floor_id"
                      @change="
                        getRooms();
                        newSubscription.room_id = null;
                      "
                    >
                      <option :value="null">Select Floor</option>
                      <option
                        v-for="floor in floors"
                        :value="floor.floor_id"
                        :key="floor.floor_id"
                      >
                        {{ floor.floor_name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="room">Room Category</label>
                    <select
                      id="room"
                      v-model="newSubscription.room_category_id"
                      @change="getRooms()"
                    >
                      <option :value="null">Select Category</option>
                      <option
                        v-for="roomClass in roomClasses"
                        :value="roomClass.room_category_id"
                        :key="roomClass.room_category_id"
                      >
                        {{ roomClass.room_category_name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="room">Room</label>
                    <select
                      id="room"
                      v-model="newSubscription.room_id"
                      @change="updateSubscriptionAmount()"
                    >
                      <option value="">Select Room</option>
                      <option
                        v-for="room in rooms"
                        :value="room.room_id"
                        :key="room.room_id"
                        :title="room.room_price"
                      >
                        {{ room.room_number }} ({{ $comma(room.room_price) }}
                        {{ this.$getHouse().currency }})
                      </option>
                    </select>
                  </div>
                </div>

                <hr />
                <p><i>Subscription Details:</i></p>
                <div class="input-group group-3">
                  <div class="form-group">
                    <label for="type">Subscription Type</label>
                    <select
                      id="type"
                      v-model="newSubscription.subscription_type"
                    >
                      <option value="">Select Subscription Type</option>
                      <option value="Daily">Daily</option>
                      <option value="Weekly">Weekly</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Quarterly">Quarterly</option>
                      <option value="Yearly">Yearly</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="recurring">Payment Mode</label>
                    <select
                      id="recurring"
                      v-model="newSubscription.payment_mode"
                    >
                      <option value="">Select Payment Mode</option>
                      <option value="Cash">Cash</option>
                      <option value="Mobile Money">Mobile Money</option>
                      <option value="Bank Transfer">Bank Transfer</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="start">Start Date</label>
                    <input
                      type="date"
                      id="start"
                      v-model="newSubscription.started_at"
                    />
                  </div>
                </div>
                <hr />
                <p>
                  <i
                    >Rental Payment Details({{ this.$getHouse().currency }}):</i
                  >
                </p>
                <div class="input-group group-1 payment-list-details">
                  <div class="payment-list-details-inputs">
                    <div
                      class="payment-list-details-input"
                      v-for="(payment, index) in newSubscription.payments"
                      :key="index"
                    >
                      <div class="form-group">
                        <label for="description">Payment Title</label>
                        <input
                          type="text"
                          v-model="payment.description"
                          placeholder="Payment Description"
                        />
                      </div>
                      <div class="form-group">
                        <label for="amount"
                          >Amount/{{ $frequency(true) }}</label
                        >
                        <input
                          type="number"
                          v-model="payment.amount"
                          placeholder="Payment Amount"
                          @change="calculateTotal()"
                          min="0"
                        />
                      </div>
                      <div class="form-group">
                        <label for="paid_number">Paid {{ $frequency() }}</label>
                        <input
                          type="number"
                          v-model="payment.paid_number"
                          placeholder="Paid Number"
                          @change="calculateTotal()"
                          min="0"
                        />
                      </div>
                      <div class="check">
                        <input type="checkbox" v-model="payment.recurring" />
                        <p>Recurring</p>
                      </div>
                      <span
                        class="danger"
                        @click="
                          newSubscription.payments.splice(index, 1);
                          calculateTotal();
                        "
                      >
                        <i class="fa fa-trash"></i>
                      </span>
                    </div>

                    <span
                      class="btn"
                      @click="
                        newSubscription.payments.push({
                          description: '',
                          amount: 0,
                          paid_number: 1,
                          recurring: false,
                        })
                      "
                    >
                      <i class="fa fa-plus"></i> Add
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="description">Description</label>
                  <textarea
                    id="description"
                    v-model="newSubscription.description"
                    placeholder="Subscription Description"
                    class="form-control"
                  ></textarea>
                </div>
              </div>
              <div class="flex-btn">
                <button class="my-btn" @click="addSubscription()">
                  <i class="fa fa-save"></i>
                  &nbsp;Save Subscription
                </button>
                <button class="my-btn red" @click="showList = true">
                  <i class="fa fa-times"></i> &nbsp;Cancel
                </button>
              </div>
            </div>
          </div>
          <div class="tenant-detail-item" v-if="activeTab == 'bills'">
            <div class="flex-btn">
              <h3 class="detail-header">Bills</h3>
              <button class="my-btn">
                <i class="fa fa-plus"></i> Add Custom Bill
              </button>
            </div>

            <div
              class="payment-table"
              v-if="
                tenantBills &&
                tenantBills.data.data &&
                tenantBills.data.data.length > 0
              "
            >
              <table class="bills-table">
                <thead>
                  <tr>
                    <th>
                      <div class="flex">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          v-model="allSelected"
                          @click="selectAll"
                        />
                        Bill
                      </div>
                    </th>
                    <th></th>
                    <th>Room</th>
                    <th>Tenant</th>
                    <th>Issued</th>
                    <th>Due Date</th>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="bills-table-body"
                    v-for="bill in tenantBills.data.data"
                    :key="bill.id"
                    @click="selectItem(bill.id)"
                  >
                    <td colspan="2">
                      <span class="check flex">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          :checked="selectedItems.includes(bill.id)"
                        />
                        <p>{{ bill.bill_description }}</p></span
                      >
                    </td>
                    <td>
                      <span v-if="bill.room">{{ bill.room.room_number }}</span>
                      <span v-else>-</span>
                    </td>
                    <td>{{ bill.tenant.tenant_name }}</td>

                    <td>{{ $date(bill.created_at) }}</td>
                    <td>{{ $date(bill.due_date) }}</td>
                    <td>
                      <small
                        :class="
                          bill.status != 'paid' && bill.due_date < today
                            ? 'chip overdue'
                            : 'chip ' + bill.status
                        "
                        >{{
                          bill.status == "pending" && bill.due_date < today
                            ? $overGracePeriod(bill.due_date, today)
                              ? "overdue"
                              : "Grace"
                            : bill.status
                        }}</small
                      >
                    </td>
                    <td>{{ $comma($fp(bill.amount)) }}</td>
                    <td>
                      <a-dropdown-button>
                        Actions
                        <template #overlay>
                          <a-menu>
                            <a-menu-item
                              key="0"
                              @click="
                                selectedBill = bill;
                                showBill = true;
                              "
                            >
                              <i class="fa fa-eye"></i> View Bill
                            </a-menu-item>
                            <a-menu-item
                              key="1"
                              @click="
                                selectedItem = bill;
                                startReminder = true;
                              "
                              v-if="bill.status != 'paid'"
                            >
                              <i class="fa fa-envelope"></i> Send Reminder
                            </a-menu-item>
                            <a-menu-item
                              key="2"
                              @click="
                                selectedItem = bill;
                                startConfirm = true;
                              "
                              v-if="bill.status != 'paid'"
                            >
                              <i class="fa fa-check"></i> Confirm Payment
                            </a-menu-item>
                            <a-menu-item
                              key="3"
                              @click="
                                selectedItem = bill;
                                startRevoke = true;
                              "
                              v-if="bill.status == 'paid'"
                            >
                              <i class="fa fa-times"></i> Revoke Payment
                            </a-menu-item>
                          </a-menu>
                        </template>
                        <template #icon
                          ><i class="fa fa-angle-down"></i
                        ></template>
                      </a-dropdown-button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="pagination">
                <button
                  :class="{ disabled: currentPage === 1 }"
                  @click="$previousPage()"
                >
                  <label>Previous</label>
                </button>
                <p v-if="currentPage > 3">...</p>
                <button
                  class="numbers"
                  v-for="number in pagesNumber"
                  :key="number"
                >
                  <label
                    :class="{ active: currentPage === number }"
                    for=""
                    v-if="
                      (number == 1 && currentPage <= 2) ||
                      (currentPage + 2 >= pagesNumber &&
                        number >= pagesNumber - 2) ||
                      (number >= currentPage - 2 && number <= currentPage + 2)
                    "
                    @click="$changePage(number)"
                  >
                    {{ number }}
                  </label>
                </button>
                <p v-if="currentPage <= pagesNumber - 3">...</p>
                <button
                  :class="{ disabled: currentPage === pagesNumber.length }"
                  @click="$nextPage()"
                >
                  <label>Next</label>
                </button>
              </div>
            </div>
            <div v-else>
              <i class="no-data">No bills found</i>
            </div>
          </div>
        </div>
      </div>

      <a-modal
        v-model:open="startConfirm"
        title="Confirm Payment"
        :size="'600px'"
        @ok="confirmItem"
      >
        <div class="form-group group-1">
          <div class="input-group">
            <label for="name">Payment Mode</label>
            <select
              id="name"
              v-model="selectedItem.payment_mode"
              class="form-control"
            >
              <option value="">Select payment mode</option>
              <option value="Cash">Cash</option>
              <option value="Mobile Money">Mobile Money</option>
              <option value="Bank Transfer">Bank Transfer</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div class="input-group">
            <label for="name">Message</label>
            <textarea
              id="name"
              v-model="selectedItem.message"
              class="form-control"
            ></textarea>
          </div>
        </div>
      </a-modal>

      <a-modal
        v-model:open="startRevoke"
        title="Revoke Payment"
        :size="'600px'"
        @ok="revokeItem"
      >
        <div class="form-group group-1">
          <div class="input-group">
            <label for="name">Reason</label>
            <textarea
              id="name"
              v-model="selectedItem.revoke_reason"
              class="form-control"
            ></textarea>
          </div>
        </div>
      </a-modal>
      <a-modal
        v-model:open="startReminder"
        title="Send Reminder"
        :size="'600px'"
        @ok="sendReminder()"
      >
        <p>Are you sure you want to send a reminder to this tenant?</p>
      </a-modal>

      <a-modal
        v-model:open="showRenew"
        title="Renew Subscription"
        :size="'600px'"
        @ok="renewSubscription"
      >
        <div class="admin-forms">
          <div class="input-group group-1">
            <div class="form-group">
              <label for="amount" v-if="renewData.subscription_type == 'Daily'"
                >Number of paid Days</label
              >
              <label
                for="amount"
                v-if="renewData.subscription_type == 'Monthly'"
                >Number of paid Months</label
              >
              <label
                for="amount"
                v-if="renewData.subscription_type == 'Quarterly'"
                >Number of paid Quarters</label
              >
              <label for="amount" v-if="renewData.subscription_type == 'Yearly'"
                >Number of paid Years</label
              >
              <input
                type="number"
                id="amount"
                v-model="renewData.number_paid"
                min="1"
              />
            </div>
            <div class="form-group">
              <label for="amount">Payment Mode</label>
              <select id="amount" v-model="renewData.payment_mode">
                <option value="">Select Payment Mode</option>
                <option value="Cash">Cash</option>
                <option value="Mobile Money">Mobile Money</option>
                <option value="Bank Transfer">Bank Transfer</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
        </div>
      </a-modal>

      <a-modal
        v-model:open="showEnd"
        title="End Subscription"
        :size="'600px'"
        @ok="endSubscription"
      >
        <p>Are you sure you want to end this subscription?</p>
      </a-modal>

      <a-modal
        v-model:open="showBill"
        :title="'Bill: #' + selectedBill.bill_number"
        :size="'600px'"
        v-if="selectedBill"
        footer=""
      >
        <div class="bill-preview no-shadow">
          <div class="bill-preview-header">
            <h1>
              {{ selectedBill.house.house_name }}
            </h1>
            <h2>{{ selectedBill.bill_number }}</h2>
            <div class="contact-info">
              <p>
                {{ selectedBill.house.house_address }}
              </p>
              <p>
                {{ selectedBill.house.house_phone }}
              </p>
              <p>
                {{ selectedBill.house.house_email }}
              </p>

              <p>
                {{
                  $date(new Date()) +
                  " " +
                  new Date().getHours() +
                  ":" +
                  new Date().getMinutes() +
                  ":" +
                  new Date().getSeconds()
                }}
              </p>
              <p>TIN: {{ selectedBill.house.house_tin_number }}</p>
            </div>
            <div class="contact-info">
              <p><b>Billed To:</b></p>
              <p>{{ selectedBill.tenant.tenant_name }}</p>
            </div>
            <div class="bill-items">
              <table>
                <tr>
                  <th>Item</th>
                  <th>UP</th>
                  <th>Count</th>
                  <th>Amount</th>
                </tr>
                <tr v-for="item in selectedBill.bill_details" :key="item.id">
                  <td>{{ item.bill_name }}</td>
                  <td>
                    {{ $comma(item.bill_price) }} {{ $getHouse().currency }}
                  </td>
                  <td>{{ item.bill_quantity }}</td>
                  <td>
                    {{ $comma(item.bill_price * item.bill_quantity) }}
                    {{ $getHouse().currency }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td><b>Total</b></td>
                  <td>
                    <b
                      >{{ $comma(selectedBill.amount) }}
                      {{ $getHouse().currency }}</b
                    >
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="print-btn">
            <button @click="printBill">Print Bill</button>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      newItem: {
        name: "",
        address: "",
        image: "",
      },
      selectedItems: [],
      allSelected: false,
      selectedItem: null,
      startConfirm: false,
      startRevoke: false,
      allItemsCount: 100,
      itemsPerpage: 10,
      pagesNumber: 0,
      currentPage: 1,
      searchQuery: {
        search: "",
        tenant_type: "",
        wing_id: "",
        status: "",
      },
      activeTenant: null,
      tenantBills: null,
      activeTab: "profile",
      today: new Date().toISOString().split("T")[0],
      startReminder: false,
      showList: true,
      showRenew: false,
      showEnd: false,
      roomClasses: [],
      floors: [],
      wings: [],
      rooms: [],
      newSubscription: {
        house_id: null,
        tenant_id: null,
        wing_id: null,
        floor_id: null,
        room_category_id: null,
        room_id: "",
        subscription_type: "Monthly",
        recurring: "",
        started_at: "",
        payments: [
          {
            description: "Rental Fees",
            amount: 0,
            recurring: true,
            paid_number: 1,
          },
          {
            description: "Bail amount",
            amount: 0,
            recurring: false,
            paid_number: 1,
          },
        ],
        number_paid: 1,
        amount: 0,
        payment_mode: "Mobile Money",
        bailIncluded: true,
        bailAmount: 0,
        bailPaymentMode: "Mobile Money",
        bailDescription: "Bail amount for the unit",
        subscriptionActivity: "Add",
        description:
          "Initial Subscription for 2 months with 1 month bail for room ...",
      },
      renewData: {
        amount: null,
        number_paid: null,
        subscription_id: null,
        house_id: null,
        payment_mode: null,
        payments: [],
        subscription_type: null,
      },
      selectedBill: null,
      showBill: false,
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_TENANT_BY_ID", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            house_id: this.$getHouseId(),
            searchQuery: this.searchQuery,
            tenant_id: this.$route.params.id,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.activeTenant = res.data;
              this.getBills();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getBills() {
      this.$startLoader();
      this.$store
        .dispatch("GET_BILLS_BY_TENANT", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            house_id: this.$getHouseId(),
            searchQuery: this.searchQuery,
            tenant_id: this.$route.params.id,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.tenantBills = res.data;
              this.pagesNumber = Math.ceil(
                this.tenantBills.data.total / this.itemsPerpage
              );
              this.allItemsCount = this.tenantBills.data.total;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    confirmItem() {
      if (this.selectedItem.payment_mode) {
        this.selectedItem.status = "paid";
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_BILL", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success("Payment confirmed successfully");
                this.getItems();
                this.startConfirm = false;
                this.$stopLoader();
              } else {
                toast.error("Something went wrong, please try again later.");
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    revokeItem() {
      this.selectedItem.status = "pending";
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_BILL", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success("Payment revoked successfully");
              this.getItems();
              this.startRevoke = false;
              this.$stopLoader();
            } else {
              toast.error("Something went wrong, please try again later.");
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    selectItem(item) {
      if (this.selectedItems.includes(item)) {
        this.selectedItems = this.selectedItems.filter(
          (selectedItem) => selectedItem !== item
        );
      } else {
        this.selectedItems.push(item);
      }
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.tenantBills.data.data.map((item) => item.id);
      }
    },
    sendReminder() {
      this.$startLoader();
      this.$store
        .dispatch("SEND_REMINDER", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success("Reminder sent successfully");
              this.startReminder = false;
              this.getItems();
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.startReminder = false;
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getRooms() {
      this.$store
        .dispatch("GET_ROOMS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
            house_id: this.$getHouseId(),
            searchQuery: {
              floor_id: this.newSubscription.floor_id,
              wing_id: this.newSubscription.wing_id,
              room_category_id: this.newSubscription.room_category_id,
              status: "Available",
            },
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.rooms = res.data.data.data;
            }
          }
        })
        .catch(() => {});
    },
    calculateTotal() {
      let TotalPayments = 0;
      this.newSubscription.payments.forEach((payment) => {
        TotalPayments += payment.amount * payment.paid_number;
      });
      this.newSubscription.amount = TotalPayments;
    },
    addSubscription() {
      if (
        this.newSubscription.room_id &&
        this.newSubscription.started_at &&
        this.newSubscription.amount &&
        this.newSubscription.payment_mode &&
        this.newSubscription.subscription_type &&
        this.newSubscription.number_paid &&
        this.newSubscription.payments.length > 0
      ) {
        this.newSubscription.tenant_id = this.activeTenant.tenant_id;
        this.newSubscription.house_id = this.$getHouseId();
        this.$startLoader();
        this.$store
          .dispatch("START_SUBSCRIPTION", {
            data: this.newSubscription,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success("Subscription added successfully");
                this.getItems();
                this.showList = true;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateSubscription() {
      if (
        this.newSubscription.room_id &&
        this.newSubscription.started_at &&
        this.newSubscription.amount &&
        this.newSubscription.payment_mode &&
        this.newSubscription.subscription_type &&
        this.newSubscription.number_paid &&
        this.newSubscription.payments.length > 0
      ) {
        this.newSubscription.tenant_id = this.activeTenant.tenant_id;
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_SUBSCRIPTION", {
            data: this.newSubscription,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success("Subscription updated successfully");
                this.getItems();
                this.showList = true;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    renewSubscription() {
      if (
        this.renewData.number_paid &&
        this.renewData.payment_mode &&
        this.renewData.subscription_id
      ) {
        let totalAmount = 0;
        this.renewData.payments.forEach((payment) => {
          totalAmount += payment.amount;
        });
        totalAmount = totalAmount * this.renewData.number_paid;
        this.renewData.amount = totalAmount;
        this.$startLoader();
        this.$store
          .dispatch("RENEW_SUBSCRIPTION", {
            data: this.renewData,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success("Subscription renewed successfully");
                this.getItems();
                this.showRenew = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    endSubscription() {
      this.$startLoader();
      this.$store
        .dispatch("END_SUBSCRIPTION", {
          data: this.newSubscription,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success("Subscription ended successfully");
              this.getItems();
              this.showEnd = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    startRenewSubscription(sub) {
      this.renewData = {
        number_paid: 1,
        subscription_id: sub.subscription_id,
        house_id: this.$getHouseId(),
        payment_mode: "Mobile Money",
        payments: sub.payments,
        subscription_type: sub.subscription_type,
      };
      this.showRenew = true;
    },
    startEndSubscription(sub) {
      this.newSubscription = sub;
      this.showEnd = true;
    },
    updateSubscriptionAmount() {
      this.newSubscription.payments[0].amount = this.rooms.find(
        (room) => room.room_id === this.newSubscription.room_id
      ).room_price;
      this.calculateTotal();
    },
    $frequency(singular = false) {
      switch (this.newSubscription.subscription_type) {
        case "Daily":
          return singular ? "day" : "days";
        case "Weekly":
          return "weeks";
        case "Monthly":
          return singular ? "month" : "months";
        case "Quarterly":
          return singular ? "quarter" : "quarters";
        case "Yearly":
          return singular ? "year" : "years";
      }
    },
    getTotal() {
      let total = 0;
      this.newSubscription.payments.forEach((payment) => {
        total += payment.amount * payment.paid_number;
      });
      return total;
    },
  },
  mounted() {
    if (this.$access("Tenants", "read")) {
      this.getItems();
      this.getRooms();
      this.$getWings();
      this.$getRoomClasses();
      this.$getFloors();
      if (this.$route.query.tab) {
        this.activeTab = this.$route.query.tab;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .dash-content {
    padding: 0 2rem;
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
      }
    }
  }
}

.tenant-view-section {
  display: grid;
  grid-template-columns: 350px 1fr;
  margin-top: 3rem;
  gap: 2rem;
  align-items: flex-start;
  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
  .left {
    .tenant-card {
      width: 100%;
      background: #fff;
      border-radius: 3px;
      padding: 1rem;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.062);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .img {
        text-align: center;
        margin-top: 1rem;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .name {
        text-align: center;
        font-size: 1.25rem;
        font-weight: 500;
        margin-top: 0.75rem;
        color: #444;
      }
      .occupation {
        text-align: center;
        font-size: 0.8rem;
        color: $primary_color;
        margin-top: 0.5rem;
        margin-bottom: 2rem;
        background: rgba(46, 167, 204, 0.1);
        padding: 0.35rem 1rem;
        display: inline-block;
        text-transform: capitalize;
        border-radius: 30px;
        font-weight: 500;
      }
      .card-stats {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 1rem;
        width: 100%;
        .card-stat-item {
          text-align: center;
          h3 {
            font-size: 0.8rem;
            color: #666;
          }
          p {
            font-size: 1.15rem;
            font-weight: 600;
            color: #333;
          }
        }
      }
      .card-footer {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        padding-top: 1rem;
        gap: 3rem;
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.062);
        a {
          padding: 0.5rem 1rem;
          border-radius: 50px;
          color: #fff;
          text-decoration: none;
          display: flex;
          align-items: center;
          font-size: 0.9rem;
          i {
            margin-right: 0.5rem;
          }
        }
        .call {
          background: #2ecc71;
          box-shadow: 0 0 10px rgba(46, 204, 113, 0.5);
          &:hover {
            background: #27ae60;
          }
        }
        .email {
          background: #3498db;
          box-shadow: 0 0 10px rgba(52, 152, 219, 0.5);
          &:hover {
            background: #2980b9;
          }
        }
      }
    }
  }
  .tenant-details {
    background: #fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.062);
    border-radius: 3px;
    .details-tabs {
      display: flex;
      gap: 1rem;
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 1.5rem;
      button {
        padding: 0.75rem 1rem;
        border: none;
        background: #f9f9f9;
        color: #333;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 3px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          background: #f1f1f1;
        }
        &.active {
          background: $primary_color;
          color: #fff;
        }
      }
    }
    .tenant-detail-item {
      position: relative;
      background: #f7f7f7;
      padding: 1rem;
      .detail-header {
        font-size: 1.25rem;
        font-weight: 500;
        color: #333;
        margin-bottom: 1rem;
        &:after {
          content: "";
          display: block;
          width: 50px;
          height: 2px;
          background: $primary_color;
          margin-top: 0.5rem;
        }
      }
      .detail-content {
        p {
          font-size: 0.9rem;
          color: #333;
          margin-bottom: 0.5rem;
          line-height: 1.5;
          b {
            color: #666;
            font-weight: 600;
          }
        }
        .subscription {
          margin-top: 1rem;
          padding: 1rem;
          background: #f9f9f9;
          border-radius: 3px;
          border: 1px dashed #a7a7a7;
          .room {
            font-size: 1.25rem;
            font-weight: 500;
            color: #333;
            margin-bottom: 1rem;
          }
          p {
            font-size: 0.9rem;
            line-height: 1.5;
            color: #333;
            margin-bottom: 0.5rem;
            b {
              color: #666;
            }
          }
          .payments {
            margin-top: 1rem;
            .payment {
              p {
                font-size: 0.9rem;
                color: #333;
                margin-bottom: 0.5rem;
                b {
                  color: #666;
                }
              }
              span {
                font-size: 0.8rem;
                font-weight: 500;
                color: #333;
                margin-bottom: 0.5rem;
              }
            }
          }
        }
      }
      .no-sub {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        button {
          padding: 1.5rem 3rem;
          background: $primary_color;
          color: #fff;
          border: none;
          border-radius: 3px;
          cursor: pointer;
          transition: all 0.3s;
          font-size: 1rem;
          &:hover {
            background: $secondary_color;
          }
        }
      }
    }
    .subscription-list {
      display: none;
      transition: display 0.5s;
      &.active {
        display: block;
      }
    }
    .add-subscription-form-section {
      display: none;
      transition: display 0.5s;
      &.active {
        display: block;
      }
      hr {
        border: none;
        border-top: 1px solid #e2e2e2;
        margin-bottom: 1rem;
      }
      .payment-list-details {
        padding: 1rem;
        background: #ececec;
        border: 1px dashed #afafaf;
        border-radius: 3px;
        .payment-list-details-inputs {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .payment-list-details-input {
            display: flex;
            gap: 1rem;
            align-items: center;
            input {
              padding: 0.5rem 1rem;
              border: 1px solid #e2e2e2;
              border-radius: 3px;
              width: 100%;
              font-size: 0.9rem;
              background: #fff;
              &:focus {
                outline: none;
                border-color: $primary_color;
              }
            }
            span {
              padding: 0.5rem 1rem;
              background: #833a3a;
              border-radius: 3px;
              cursor: pointer;
              i {
                color: #fff;
              }
            }
            .check {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              p {
                font-size: 0.9rem;
                color: #333;
                margin-bottom: 0;
              }
            }
          }
          .btn {
            padding: 0.5rem 1rem;
            background: $primary_color;
            color: #fff;
            border: none;
            border-radius: 3px;
            cursor: pointer;
            transition: all 0.3s;
            font-size: 1rem;
            display: inline-block;
            width: fit-content;
            &:hover {
              background: $secondary_color;
            }
          }
        }
      }
    }
  }
}
</style>