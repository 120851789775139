import AdminOrganizations from "../views/admin/Organizations/AdminOrganizations.vue";
import AdminOrganizationAdmins from "../views/admin/Organizations/AdminOrganizationAdmins.vue";
import AdminTenants from "../views/admin/Tenants/AdminTenants.vue";
import AdminAddTenants from "../views/admin/Tenants/AdminAddTenants.vue";
import AdminViewTenant from "../views/admin/Tenants/AdminViewTenant.vue";
import AdminEmployees from "../views/admin/Employees/AdminEmployees.vue";
import AdminAddEmployee from "../views/admin/Employees/AdminAddEmployee.vue";
import AdminRoles from "../views/admin/Employees/AdminRoles.vue";
import AdminAddRole from "../views/admin/Employees/AdminAddRole.vue";
import AdminPayments from "../views/admin/Payments/AdminPayments.vue";
import AdminUnpaidInvoices from "../views/admin/Payments/AdminUnpaidInvoices.vue";
import AdminBookingRequests from "../views/admin/Requests/AdminBookingRequests.vue";
import AdminCancellationRequests from "../views/admin/Requests/AdminCancellationRequests.vue";
import AdminFloors from "../views/admin/Configurations/AdminFloors.vue";
import AdminWings from "../views/admin/Configurations/AdminWings.vue";
import AdminRooms from "../views/admin/Configurations/AdminRooms.vue";
import AdminRoomClasses from "../views/admin/Configurations/AdminRoomClasses.vue";
import AdminAnalytics from "../views/admin/AdminAnalytics.vue";
import AdminComplaints from "../views/admin/Complaints/AdminComplaints.vue";
import AdminMessages from "../views/admin/Messages/AdminMessages.vue";
import AdminPosts from "../views/admin/Posts/AdminPosts.vue";
import AdminViewPost from "../views/admin/Posts/AdminViewPost.vue";
import AdminEvents from "../views/admin/Events/AdminEvents.vue";
import AdminPermissions from "../views/admin/Permissions/AdminPermissions.vue";
import AdminReports from "../views/admin/Reports/AdminReports.vue";
import AdminHouse from "../views/admin/Configurations/AdminHouse.vue";
const SAdminRoutes = [
  {
    path: "/admin/organizations",
    name: "AdminOrganizations",
    component: AdminOrganizations,
  },
  {
    path: "/admin/organizations/:id/admins",
    name: "AdminOrganizationAdmins",
    component: AdminOrganizationAdmins,
  },
  {
    path: "/admin/analytics",
    name: "AdminAnalytics",
    component: AdminAnalytics,
  },
  {
    path: "/admin/rooms",
    name: "AdminRooms",
    component: AdminRooms,
  },
  {
    path: "/admin/tenants/all",
    name: "AdminTenants",
    component: AdminTenants,
  },
  {
    path: "/admin/tenants/add",
    name: "AdminAddTenants",
    component: AdminAddTenants,
  },
  {
    path: "/admin/tenants/all/view/:id",
    name: "AdminViewTenant",
    component: AdminViewTenant,
  },
  {
    path: "/admin/employees/all",
    name: "AdminEmployees",
    component: AdminEmployees,
  },
  {
    path: "/admin/employees/add",
    name: "AdminAddEmployee",
    component: AdminAddEmployee,
  },
  {
    path: "/admin/employees/roles",
    name: "AdminRoles",
    component: AdminRoles,
  },
  {
    path: "/admin/employees/roles/add",
    name: "AdminAddRole",
    component: AdminAddRole,
  },
  {
    path: "/admin/payments/history",
    name: "AdminPayments",
    component: AdminPayments,
  },
  {
    path: "/admin/payments/unpaid",
    name: "AdminUnpaidInvoices",
    component: AdminUnpaidInvoices,
  },
  {
    path: "/admin/requests/booking-requests",
    name: "AdminBookingRequests",
    component: AdminBookingRequests,
  },
  {
    path: "/admin/requests/cancellation-requests",
    name: "AdminCancellationRequests",
    component: AdminCancellationRequests,
  },
  {
    path: "/admin/configurations/floors",
    name: "AdminFloors",
    component: AdminFloors,
  },
  {
    path: "/admin/configurations/wings",
    name: "AdminWings",
    component: AdminWings,
  },
  {
    path: "/admin/configurations/room-classes",
    name: "AdminRoomClasses",
    component: AdminRoomClasses,
  },
  {
    path: "/admin/complaints/board",
    name: "AdminComplaints",
    component: AdminComplaints,
  },
  {
    path: "/admin/messages",
    name: "AdminMessages",
    component: AdminMessages,
  },
  {
    path: "/admin/posts",
    name: "AdminPosts",
    component: AdminPosts,
  },
  {
    path: "/admin/posts/view/:id",
    name: "AdminViewPost",
    component: AdminViewPost,
  },
  {
    path: "/admin/events",
    name: "AdminEvents",
    component: AdminEvents,
  },
  {
    path: "/admin/permissions",
    name: "AdminPermissions",
    component: AdminPermissions,
  },
  {
    path: "/admin/reports",
    name: "AdminReports",
    component: AdminReports,
  },
  {
    path: "/admin/configurations/info",
    name: "AdminHouse",
    component: AdminHouse,
  },
];
export default SAdminRoutes;
