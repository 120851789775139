<template>
  <div class="dashboard">
    <div class="dash-content" v-if="$access('Payments', 'read')">
      <h1 class="page-title">
        <span>Payments</span>
        <v-menu transition="slide-x-transition">
          <template v-slot:activator="{ props }">
            <button v-bind="props">Actions</button>
          </template>

          <div class="drop-content">
            <span> <i class="fas fa-file-download"></i> Export All </span>
            <span> <i class="fas fa-file-download"></i> Export Selected </span>
          </div>
        </v-menu>
      </h1>
      <div class="payment-stats">
        <div class="stats-card">
          <span>Total Payments</span>
          <span>{{ $comma($fp(stats.total)) }}</span>
        </div>
        <div class="stats-card">
          <span>This Month</span>
          <span>{{ $comma($fp(stats.thisMonth)) }}</span>
        </div>
        <div class="stats-card">
          <span>Last Month</span>
          <span>{{ $comma($fp(stats.lastMonth)) }}</span>
        </div>
        <div class="stats-card">
          <span>Unpaid</span>
          <span>{{ $comma($fp(stats.unpaid)) }}</span>
        </div>
      </div>

      <table class="bills-table mt-10" v-if="tenantBills.length > 0">
        <thead>
          <tr>
            <th>
              <div class="flex">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  v-model="allSelected"
                  @click="selectAll"
                />
                Bill
              </div>
            </th>
            <th></th>
            <th>Room</th>
            <th>Tenant</th>
            <th>Issued</th>
            <th>Due Date</th>
            <th>Status</th>
            <th>Amount</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="bills-table-body"
            v-for="bill in tenantBills"
            :key="bill.id"
            @click="selectItem(bill.id)"
          >
            <td colspan="2">
              <span class="flex">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  :checked="selectedItems.includes(bill.id)"
                />
                <p>{{ bill.bill_description }}</p></span
              >
            </td>
            <td>
              <span v-if="bill.room">{{ bill.room.room_number }}</span>
              <span v-else>-</span>
            </td>
            <td>{{ bill.tenant.tenant_name }}</td>

            <td>{{ $date(bill.created_at) }}</td>
            <td>{{ $date(bill.due_date) }}</td>
            <td>
              <small
                :class="
                  bill.status != 'paid' && bill.due_date < today
                    ? 'chip overdue'
                    : 'chip ' + bill.status
                "
                >{{
                  bill.status == "pending" && bill.due_date < today
                    ? $overGracePeriod(bill.due_date, today)
                      ? "overdue"
                      : "Grace"
                    : bill.status
                }}</small
              >
            </td>
            <td>{{ $comma($fp(bill.amount)) }}</td>
            <td>
              <a-dropdown-button>
                Actions
                <template #overlay>
                  <a-menu>
                    <a-menu-item
                      key="0"
                      @click="
                        selectedBill = bill;
                        showBill = true;
                      "
                    >
                      <i class="fa fa-eye"></i> View Bill
                    </a-menu-item>
                    <a-menu-item
                      key="1"
                      @click="
                        selectedItem = bill;
                        startReminder = true;
                      "
                      v-if="bill.status != 'paid'"
                    >
                      <i class="fa fa-envelope"></i> Send Reminder
                    </a-menu-item>
                    <a-menu-item
                      key="2"
                      @click="
                        selectedItem = bill;
                        startConfirm = true;
                      "
                      v-if="bill.status != 'paid'"
                    >
                      <i class="fa fa-check"></i> Confirm Payment
                    </a-menu-item>
                    <a-menu-item
                      key="3"
                      @click="
                        selectedItem = bill;
                        startRevoke = true;
                      "
                      v-if="bill.status == 'paid'"
                    >
                      <i class="fa fa-times"></i> Revoke Payment
                    </a-menu-item>
                  </a-menu>
                </template>
                <template #icon><i class="fa fa-angle-down"></i></template>
              </a-dropdown-button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else style="text-align: center; padding: 2rem">
        <i class="no-data">No bills found</i>
      </div>

      <a-modal
        v-model:open="startRevoke"
        title="Revoke Payment"
        :size="'600px'"
        @ok="revokeItem"
      >
        <div class="form-group group-1">
          <div class="input-group">
            <label for="name">Reason</label>
            <textarea
              id="name"
              v-model="selectedItem.revoke_reason"
              class="form-control"
            ></textarea>
          </div>
        </div>
      </a-modal>
      <a-modal
        v-model:open="startReminder"
        title="Send Reminder"
        :size="'600px'"
        @ok="sendReminder()"
      >
        <p>Are you sure you want to send a reminder to this tenant?</p>
      </a-modal>
      <a-modal
        v-model:open="startConfirm"
        title="Confirm Payment"
        :size="'600px'"
        @ok="confirmItem"
      >
        <div class="form-group group-1">
          <div class="input-group">
            <label for="name">Payment Mode</label>
            <select
              id="name"
              v-model="selectedItem.payment_mode"
              class="form-control"
            >
              <option value="">Select payment mode</option>
              <option value="Cash">Cash</option>
              <option value="Mobile Money">Mobile Money</option>
              <option value="Bank Transfer">Bank Transfer</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div class="input-group">
            <label for="name">Message</label>
            <textarea
              id="name"
              v-model="selectedItem.message"
              class="form-control"
            ></textarea>
          </div>
        </div>
      </a-modal>

      <a-modal
        v-model:open="showBill"
        :title="'Bill: #' + selectedBill.bill_number"
        :size="'600px'"
        v-if="selectedBill"
        footer=""
      >
        <div class="bill-preview no-shadow">
          <div class="bill-preview-header">
            <h1>
              {{ selectedBill.house.house_name }}
            </h1>
            <h2>{{ selectedBill.bill_number }}</h2>
            <div class="contact-info">
              <p>
                {{ selectedBill.house.house_address }}
              </p>
              <p>
                {{ selectedBill.house.house_phone }}
              </p>
              <p>
                {{ selectedBill.house.house_email }}
              </p>

              <p>
                {{
                  $date(new Date()) +
                  " " +
                  new Date().getHours() +
                  ":" +
                  new Date().getMinutes() +
                  ":" +
                  new Date().getSeconds()
                }}
              </p>
              <p>TIN: {{ selectedBill.house.house_tin_number }}</p>
            </div>
            <div class="contact-info">
              <p><b>Billed To:</b></p>
              <p>{{ selectedBill.tenant.tenant_name }}</p>
            </div>
            <div class="bill-items">
              <table>
                <tr>
                  <th>Item</th>
                  <th>UP</th>
                  <th>Count</th>
                  <th>Amount</th>
                </tr>
                <tr v-for="item in selectedBill.bill_details" :key="item.id">
                  <td>{{ item.bill_name }}</td>
                  <td>
                    {{ $comma(item.bill_price) }} {{ $getHouse().currency }}
                  </td>
                  <td>{{ item.bill_quantity }}</td>
                  <td>
                    {{ $comma(item.bill_price * item.bill_quantity) }}
                    {{ $getHouse().currency }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td><b>Total</b></td>
                  <td>
                    <b
                      >{{ $comma(selectedBill.amount) }}
                      {{ $getHouse().currency }}</b
                    >
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="print-btn">
            <button @click="printBill">Print Bill</button>
          </div>
        </div>
      </a-modal>
    </div>
    <div v-else>
      <h1 class="not-authorized">You are not authorized to access this page</h1>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      tenantBills: [],
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      startRevoke: false,
      startReminder: false,
      startConfirm: false,
      allItemsCount: 0,
      itemsPerpage: 10,
      pagesNumber: 0,
      currentPage: 1,
      today: new Date().toISOString().split("T")[0],
      stats: {
        total: 0,
        thisMonth: 0,
        lastMonth: 0,
        unpaid: 0,
      },
      selectedItems: [],
      allSelected: false,
      searchQuery: {
        status: "paid",
        search: "",
      },
      selectedBill: null,
      showBill: false,
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_BILLS_BY_HOUSE", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            house_id: this.$getHouseId(),
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.tenantBills = res.data.data.data;
              this.pagesNumber = Math.ceil(
                res.data.data.total / this.itemsPerpage
              );
              this.allItemsCount = res.data.data.total;
              this.stats.total = res.data.total;
              this.stats.thisMonth = res.data.thisMonth;
              this.stats.lastMonth = res.data.lastMonth;
              this.stats.unpaid = res.data.unpaid;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    selectItem(item) {
      if (this.selectedItems.includes(item)) {
        this.selectedItems = this.selectedItems.filter(
          (selectedItem) => selectedItem !== item
        );
      } else {
        this.selectedItems.push(item);
      }
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.tenantBills.map((item) => item.id);
      }
    },
    revokeItem() {
      this.selectedItem.status = "pending";
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_BILL", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success("Payment revoked successfully");
              this.getItems();
              this.startRevoke = false;
              this.$stopLoader();
            } else {
              toast.error("Something went wrong, please try again later.");
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    sendReminder() {
      this.$startLoader();
      this.$store
        .dispatch("SEND_REMINDER", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success("Reminder sent successfully");
              this.startReminder = false;
              this.getItems();
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.startReminder = false;
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    confirmItem() {
      if (this.selectedItem.payment_mode) {
        this.selectedItem.status = "paid";
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_BILL", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success("Payment confirmed successfully");
                this.getItems();
                this.startConfirm = false;
                this.$stopLoader();
              } else {
                toast.error("Something went wrong, please try again later.");
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
  },
  mounted() {
    if (this.$access("Payments", "read")) {
      this.getItems();
    }
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .dash-content {
    padding: 0 2rem;
  }
}
.payment-stats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  margin-top: 1rem;
  .stats-card {
    padding: 1rem;
    border-radius: 5px;
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      font-size: 0.8rem;
      color: $secondary_color;
      font-weight: 600;
      &:last-child {
        font-size: 1.5rem;
        font-weight: bold;
        color: $primary_color;
      }
    }
  }
}

.drop-content {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 1rem;
  z-index: 100;
  span {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    i {
      margin-right: 0.5rem;
    }
    &:hover {
      cursor: pointer;
      color: $primary_color;
    }
  }
}
</style>