<template>
  <div class="dashboard">
    <div class="dash-content" v-if="$access('Tenants', 'write')">
      <h1 class="page-title">
        <span>House Info</span>
        <p>Fill the form below to update house info.</p>
      </h1>
      <div class="admin-forms">
        <div class="input-group group-2">
          <div class="form-group">
            <label for="name">Name</label>
            <input
              type="text"
              id="name"
              v-model="newItem.house_name"
              placeholder="House Name"
            />
          </div>
          <div class="form-group">
            <label for="tin">TIN Number</label>
            <input
              type="text"
              id="tin"
              v-model="newItem.house_tin_number"
              placeholder="TIN Number"
            />
          </div>
        </div>
        <div class="input-group group-3">
          <div class="form-group">
            <label for="name">Address</label>
            <input
              type="text"
              id="name"
              v-model="newItem.house_address"
              placeholder="House Address"
            />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="email"
              id="email"
              v-model="newItem.house_email"
              placeholder="House Email"
            />
          </div>
          <div class="form-group">
            <label for="leader">Phone</label>
            <input
              type="tel"
              id="phone"
              v-model="newItem.house_phone"
              placeholder="House Phone"
            />
          </div>
        </div>
        <div class="input-group group-3">
          <div class="form-group">
            <label for="leader">Type</label>
            <select id="leader" v-model="newItem.house_type">
              <option value="">Select Type</option>
              <option value="Commercial">Commercial</option>
              <option value="Residential">Residential</option>
            </select>
          </div>
          <div class="form-group">
            <label for="level">Level</label>
            <select id="level" v-model="newItem.house_level">
              <option value="">Select</option>
              <option value="Single Level">Single Level</option>
              <option value="Multi Level">Multi Level</option>
            </select>
          </div>
          <div class="form-group">
            <label for="currency">Currency</label>
            <input
              type="text"
              id="currency"
              v-model="newItem.currency"
              placeholder="Currency"
            />
          </div>
        </div>

        <div class="input-group group-2">
          <div class="form-group">
            <label for="grace_period">Grace Period <small>(Days)</small></label>
            <input
              type="number"
              min="0"
              id="grace_period"
              v-model="newItem.house_grace_period"
              placeholder="Grace Period"
            />
          </div>
          <div class="form-group">
            <label for="website">Website</label>
            <input
              type="text"
              id="website"
              v-model="newItem.house_website"
              placeholder="House Website"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="image">Image</label>
          <o-upload
            :multiple="false"
            @imagesUploaded="
              (data) => {
                newItem.house_image = data;
              }
            "
            @imagesUploadedFailed="
              (data) => {
                console.log('failed');
              }
            "
            :value="newItem.house_image"
            @startLoader="() => $startLoader()"
            @stopLoader="() => $stopLoader()"
          />
        </div>
        <div class="form-group">
          <label for="other_image">Other Image</label>
          <o-upload
            :multiple="false"
            @imagesUploaded="
              (data) => {
                newItem.house_other_image = data;
              }
            "
            @imagesUploadedFailed="
              (data) => {
                console.log('failed');
              }
            "
            :value="newItem.house_other_image"
            @startLoader="() => $startLoader()"
            @stopLoader="() => $stopLoader()"
          />
        </div>
        <button @click="updateItem">Update House</button>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      wings: [],
      newItem: {
        house_tin_number: "",
        house_name: "",
        house_address: "",
        house_email: "",
        house_phone: "",
        house_type: "",
        house_level: "",
        currency: "",
        house_grace_period: "",
        house_website: "",
        house_image: [],
        house_other_image: [],
      },
      startAdd: false,
      selected: null,
      tenant_id: null,
      action: "Add",
    };
  },
  methods: {
    getHouseById(house_id) {
      this.$store
        .dispatch("GET_HOUSE_BY_ID", {
          data: {
            house_id: house_id,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            this.newItem = res.data.data;
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    updateItem() {
      if (this.newItem.house_name && this.newItem.currency) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_HOUSE", {
            data: this.newItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
  },
  mounted() {
    let house_id = this.$getHouseId();
    this.getHouseById(house_id);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 1rem;
    p {
      font-size: 14px;
      color: #686868;
      font-weight: 400;
    }
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
      }
    }
  }
}
</style>